<template>
  <div>
    <!-- Limit -->
    <div class="form-group mb-0">
      <label for="limit-type" class="custom-label">Limit</label>
      <select
        name="limit-type"
        id="limit-type"
        class="custom-select"
        v-model="campaign.new.limit.type"
        v-on:change="onChangeLimit"
        v-bind:disabled="!campaign.limitTypes.length"
      >
        <option value="-1">Limit Yok</option>
        <option
          v-for="(limit, index) in campaign.limitTypes"
          v-bind:key="limit.id"
          v-bind:value="index"
        >
          {{ limit.name }}
        </option>
      </select>
    </div>
    <!-- ./Limit -->
    <div class="mt-3" v-if="campaign.new.limit.type !== '-1'">
      <!-- Total Price -->
      <div v-if="campaign.new.limit.type == 0">
        <div class="form-group">
          <label for="limit-price" class="custom-label">Toplam tutar</label>
          <i
            class="fas fa-question-circle ml-2"
            v-tooltip.right="getTooltip"
          ></i>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="campaign.new.limit.amount"
            />
            <div class="input-group-append">
              <select class="custom-select rounded-0" v-model="limitCurrency">
                <option value="-1">Para Birimi</option>
                <option
                  v-for="currency in shared.currencyCodes.currencies"
                  v-bind:key="currency.code"
                  v-bind:value="currency.id"
                >
                  {{ currency.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- ./Total Price -->
      <div v-if="campaign.new.limit.type == 1">
        <!-- Total Usage -->
        <div class="form-group">
          <label for="limit-price" class="custom-label">Kullanım Miktarı</label>
          <i
            class="fas fa-question-circle ml-2"
            v-tooltip.right="getTooltip"
          ></i>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="campaign.new.limit.amount"
            />
            <div class="input-group-append">
              <span class="input-group-text"
                ><i class="fas fa-shopping-cart"></i
              ></span>
            </div>
          </div>
        </div>
        <!-- ./Total Usage -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Limit",
  methods: {
    onChangeLimit() {
      this.campaign.new.limit.amount = "";
    },
    setCurrencyCode() {
      if (this.limitCurrency == "-1" || this.limitCurrency == -1) {
        this.limitCurrency = this.session.config["site.currency_code"];
      }
    },
  },
  computed: {
    ...mapState(["campaign", "shared", "session"]),
    getTooltip() {
      let text =
        "Bu kampanya kullanılarak yapılan satışlar girilen tutara ulaştığında kampanya sonlanır.";
      if (this.campaign.new.limit.type == 1) {
        text =
          "Kampanya dahilinde tamamlanan sipariş sayısı girilen miktara ulaştığında kampanya sonlanır.";
      }
      return text;
    },
    limitCurrency: {
      get() {
        return this.campaign.new.limit.currency;
      },
      set(value) {
        this.campaign.new.limit.currency = value;
      },
    },
  },
  mounted() {
    this.setCurrencyCode();
  },
};
</script>
