<template>
  <WeContainer card="" v-if="ready">
    <!-- First Content -->
    <Navigation
      v-on:save-form="submitForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
    />
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <!-- Campaign Name -->
                    <WeInput
                      label="Kampanya Adı"
                      placeholder="Kampanya Adı"
                      v-model="campaignName"
                      v-bind:required="true"
                      v-bind:error="$v.campaignName.$error"
                    />
                    <!-- ./Campaign Name -->
                  </div>
                  <div class="col-12 col-md-6">
                    <!-- Slug -->
                    <WeSlugCheck
                      v-bind:value="campaignName"
                      v-bind:id="$route.params.id"
                      v-bind:slug="campaign.new.slug"
                      v-bind:search="true"
                      v-on:slugify="changeSlug"
                      url-fragment="kampanya"
                      type="campaign"
                    />
                    <!-- ./Slug -->
                  </div>
                  <div class="col-12">
                    <WeInput
                      v-bind:prepend="siteUrl"
                      v-model="campaign.new.link"
                      label="Hedef Link"
                    />
                  </div>
                </div>

                <!-- Start & End Date -->
                <div class="row mb-3">
                  <!-- Start Date -->
                  <div class="col-12 mb-3 col-lg mb-lg-0">
                    <div class="row">
                      <div class="col">
                        <label for="start-date" class="custom-label"
                          >Başlangıç Tarih/Saat</label
                        >
                      </div>
                      <!-- <div class="col-auto" v-if="$v.startTime.$error">
                <small><i class="text-danger">Zorunlu Alan</i></small>
              </div> -->
                    </div>
                    <WeDatePicker
                      id="start-date"
                      label="Başlangıç Tarih/Saat"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-model="startTime"
                    />
                  </div>
                  <!-- Start Date -->

                  <!-- End Date -->
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <label for="start-date" class="custom-label"
                          >Bitiş Tarih/Saat</label
                        >
                      </div>
                      <!-- <div class="col-auto" v-if="$v.endTime.$error">
                <small><i class="text-danger">Zorunlu Alan</i></small>
              </div> -->
                    </div>
                    <WeDatePicker
                      id="end-date"
                      label="Bitiş Tarih/Saat"
                      v-bind:format="'DD.MM.YYYY HH:mm'"
                      v-bind:output-format="'YYYY-MM-DD HH:mm'"
                      v-bind:locale="'tr'"
                      v-bind:no-label="true"
                      v-bind:no-button-now="true"
                      v-bind:no-clear-button="false"
                      v-bind:min-date="startTime"
                      v-model="endTime"
                    />
                  </div>
                  <!-- End Date -->
                </div>
                <!-- ./Start & End Date -->

                <!-- Limit -->
                <Limit class="mb-3" />
                <!-- ./Limit -->

                <!-- Description -->
                <WeInput v-model="campaign.new.description" label="Açıklama" />
                <!-- ./Description -->

                <div class="row align-items-center mb-3">
                  <div class="col-12 col-md">
                    <label class="custom-label">Kampanya Durumu</label>
                    <WeSwitch
                      v-model="campaign.new.is_active"
                      v-bind:show-status="true"
                    />
                  </div>
                  <div class="col-12 col-md">
                    <label class="custom-label"
                      >Kupon Kodu Kullanımına İzin Ver</label
                    >
                    <WeSwitch
                      v-model="campaign.new.use_coupon_code"
                      v-bind:show-status="true"
                    />
                  </div>
                  <div class="col-12 col-md">
                    <label class="custom-label">Ürün Detayda Göster</label>
                    <WeSwitch
                      v-model="campaign.new.show_product_detail"
                      v-bind:show-status="true"
                    />
                  </div>
                  <div class="col-12 col-md">
                    <label class="custom-label">Kampanya Birleştir</label>
                    <WeSwitch
                      v-model="campaign.new.combine_campaigns"
                      v-bind:show-status="true"
                    />
                  </div>
                </div>

                <template v-if="campaign.new.show_product_detail">
                  <WeImageUploader
                    v-model="campaign.new.product_detail_banner"
                    title="Ürün Detay Görseli"
                    sizeLimitMessage="En fazla <b>1024 KB</b> boyutunda <b>615x170</b> ebatında dosya yükleyebilirsiniz."
                  />
                  <hr />
                </template>
                <WeImageUploader
                  v-model="campaign.new.image"
                  title="Kampanya Görseli"
                  ref="imageuploader"
                />

                <!-- ./First Content -->

                <!-- Conditionts -->
                <WeCard>
                  <ul class="nav nav-pills mb-3" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        v-bind:class="{ active: !newCampaign.template.based }"
                        id="campaign-wizard"
                        data-toggle="pill"
                        href="#campaign-tab"
                        role="tab"
                        aria-controls="campaign-tab"
                        aria-selected="true"
                        ><i class="fas fa-magic mr-2"></i> Kampanya Sihirbazı</a
                      >
                    </li>
                    <li class="nav-item ml-2" role="presentation">
                      <a
                        class="nav-link"
                        v-bind:class="{ active: newCampaign.template.based }"
                        id="campaign-template-tab"
                        data-toggle="pill"
                        href="#campaign-template"
                        role="tab"
                        aria-controls="campaign-template"
                        aria-selected="false"
                        ><i class="fas fa-swatchbook mr-2"></i> Kampanya
                        Şablonları</a
                      >
                    </li>
                  </ul>
                  <hr class="my-4" />
                  <!-- Tab Content -->
                  <div class="tab-content">
                    <!-- Wizard -->
                    <div
                      class="tab-pane fade"
                      v-bind:class="{
                        'show active': !newCampaign.template.based,
                      }"
                      id="campaign-tab"
                      role="tabpanel"
                      aria-labelledby="campaign-wizard"
                    >
                      <WeCondition
                        v-if="!newCampaign.template.id"
                        v-bind:conditions.sync="conditions"
                        v-bind:result.sync="newCampaign.result"
                        v-bind:source="getSourceClone"
                        v-on:get-options="getOptions"
                        v-on:search-options="searchOptions"
                      />
                      <WeCard v-else class="alert-primary">
                        <i class="fas fa-info-circle"></i> Kampanya sihirbazını
                        kullanabilmek için şablonu temizlemelisiniz.
                      </WeCard>
                    </div>
                    <!-- Wizard -->

                    <div
                      class="tab-pane fade"
                      v-bind:class="{
                        'show active': newCampaign.template.based,
                      }"
                      id="campaign-template"
                      role="tabpanel"
                      aria-labelledby="campaign-template"
                    >
                      <ConditionTemplate
                        v-bind:conditions.sync="conditions"
                        v-bind:result.sync="newCampaign.result"
                        v-bind:template.sync="newCampaign.template"
                        v-bind:source.sync="campaign.data.template_copy"
                        v-bind:template-base.sync="newCampaign.template.based"
                        v-on:get-options="getOptions"
                        v-on:search-options="searchOptions"
                      />
                    </div>
                  </div>
                  <!-- Tab Content -->
                </WeCard>
                <!-- ./Conditionts -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Limit from "./views/Limit/Index";
import ConditionTemplate from "./views/ConditionTemplate/Index";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
    };
  },

  methods: {
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions("campaign", [
      "create",
      "getById",
      "update",
      "load",
      "conditionGet",
      "conditionSearch",
      "getLimitTypes",
    ]),
    getOptions(attr) {
      this.conditionGet({
        route: attr.route,
        onSuccess: (result) => {
          const data = result.data;
          if (data.hasOwnProperty("items")) {
            helper.copy(data.items, attr.options);
          } else {
            helper.copy(data, attr.options);
          }
        },
      });
    },
    searchOptions(data) {
      clearTimeout(this.timer);

      const form = {};
      const routeName = ["category", "brand", "member"];
      const conditionCode = data.attr.condition_code;
      let includeData = data.attr.name;

      switch (conditionCode) {
        case "brand":
          includeData = "brand";
          break;

        case "category":
          includeData = "category";
          break;

        case "member":
          includeData = "member";
          break;
      }

      if (routeName.includes(includeData)) {
        form.name = data.text;
      } else {
        form.query = data.text;
      }

      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        this.conditionSearch({
          route: data.search_route,
          form,
          onSuccess: (result) => {
            const resultData = result.data;
            if (resultData.hasOwnProperty("items")) {
              data.attr.options = resultData.items;
            } else {
              data.attr.options = resultData;
            }
          },
        });
      }, 1500);
    },
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }

      this.submit = true;
      if (this.$route.params.id) {
        this.updateForm();
      } else {
        this.addForm();
      }
    },
    addForm() {
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kampanyalar listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onError: (error) => {
          this.$toast.error("Kampanya eklenemedi");
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateForm() {
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kampanyalar listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getCampaignById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/campaigns");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.campaign.new.slug = data;
    },
  },
  components: {
    Limit,
    ConditionTemplate,
    Navigation,
  },
  validations() {
    if (!this.newCampaign.template.id) {
      return {
        campaignName: {
          required,
        },
        /*
         *  TODO: required list
         */
      };
    } else {
      return {
        campaignName: {
          required,
        },
      };
    }
  },
  computed: {
    ...mapState(["campaign", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "KAMPANYA DÜZENLE" : "KAMPANYA EKLE";
    },
    checkPermission() {
      return permission.check("campaign", "u");
    },
    siteUrl() {
      let configUrl = this.session.config["site.url"];
      let result = "";

      if (configUrl) {
        let lastChar = configUrl.substr(-1);
        if (lastChar !== "/") {
          configUrl += "/";
        }
        if (this.urlFragment) {
          return configUrl + this.urlFragment + "/";
        }
        return configUrl;
      }

      return result;
    },
    newCampaign: {
      get() {
        return this.campaign.new;
      },
      set(value) {
        this.campaign.new = value;
      },
    },
    campaignName: {
      get() {
        return this.newCampaign.name;
      },
      set(value) {
        this.newCampaign.name = value;
      },
    },
    startTime: {
      get() {
        return this.newCampaign.datetime.start;
      },
      set(value) {
        this.newCampaign.datetime.start = value;
      },
    },
    endTime: {
      get() {
        return this.newCampaign.datetime.end;
      },
      set(value) {
        this.newCampaign.datetime.end = value;
      },
    },
    today() {
      return new Date().toISOString();
    },
    conditions: {
      get() {
        return this.newCampaign.conditions;
      },
      set(value) {
        this.newCampaign.conditions = value;
      },
    },
    getSourceClone() {
      return helper.clone(this.campaign.data.source);
    },
  },
  mounted() {
    this.load();
    this.ready = false;
    this.getLimitTypes({
      onFinish: () => {
        this.getCampaignById({
          onFinish: () => {
            this.ready = true;
          },
        });
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.field.has-error .field-input {
  padding-top: 0 !important;
}
.sticky-md-top {
  z-index: 2 !important;
}
.datepicker {
  z-index: 1 !important;
}
</style>
