<template>
  <div>
    <!-- Template Bases -->
    <ul class="list-group list-group-horizontal mb-4">
      <li
        class="nav-link list-group-item py-2"
        v-bind:class="{
          'bg-secondary text-white': base.name == templateBase,
          'btn-light': base.name !== templateBase,
        }"
        v-for="base in baseList"
        v-bind:key="base.name"
        v-on:click="changeBase(base)"
      >
        {{ base.title }}
      </li>
    </ul>
    <!-- Template Bases -->

    <!-- Template Selection -->
    <ul
      class="list-group mb-3"
      v-if="source[templateBase] && source[templateBase].length"
    >
      <li
        class="list-group-item"
        v-for="sourceTemplate in source[templateBase]"
        v-bind:key="sourceTemplate.name"
        v-bind:class="{
          'text-primary': data.template.id == sourceTemplate.template_id,
        }"
      >
        <div class="custom-control custom-radio">
          <input
            type="radio"
            v-bind:id="sourceTemplate.template_id"
            name="template"
            class="custom-control-input"
            v-model="data.template.id"
            v-bind:value="sourceTemplate.template_id"
          />
          <label
            class="custom-control-label"
            style="padding-top: 1.5px"
            v-bind:for="sourceTemplate.template_id"
            >{{ sourceTemplate.template_description }}</label
          >
        </div>
      </li>
    </ul>
    <!-- Template Selection -->

    <WeCard v-else class="alert-warning mb-3">
      <i class="fas fa-exclamation-triangle"></i>
      <span v-if="templateBase">
        {{ getBaseTitle }} için hazır şablon bulunmamaktadır.
      </span>
      <span v-else> Lütfen yukarıdan Şablon bölümü seçiniz. </span>
    </WeCard>

    <!-- Template Content -->
    <WeCard
      class="mb-3"
      v-if="data.template && data.template.id && data.template.id !== '-1'"
    >
      <div class="row align-items-center">
        <!-- Template Condition -->
        <div
          class="col-auto pr-0"
          v-for="(attr, index) in computedTemplate.attributes"
          v-bind:key="attr.name + index"
        >
          <div class="form-group mb-xl-0">
            <div class="d-flex align-items-center">
              <!-- Before Text -->
              <span v-if="attr.before_text" class="col-auto p-0 mr-2">{{
                attr.before_text
              }}</span>
              <!-- ./Before Text -->

              <!-- Date -->
              <template v-if="attr.type == 'date'">
                <WeDatePicker
                  v-bind:id="attr.name + '-date'"
                  v-bind:format="'DD.MM.YYYY HH:mm'"
                  v-bind:output-format="'YYYY-MM-DD HH:mm'"
                  v-bind:locale="'tr'"
                  v-bind:label="attr.placeholder"
                  v-bind:no-label="true"
                  v-bind:no-button-now="true"
                  v-bind:no-clear-button="false"
                  v-model="attr.value"
                />
              </template>
              <!-- ./Date -->

              <!-- Quantity -->
              <template v-if="attr.type == 'quantity' || attr.type == 'price'">
                <WeInput
                  v-bind:placeholder="attr.placeholder"
                  v-bind:filter-number="true"
                  v-model="attr.value"
                  class="mb-0"
                  style="max-width: 90px"
                />
              </template>
              <!-- ./Quantity -->

              <!-- Select Options -->
              <template v-if="attr.type == 'select'">
                <v-select
                  v-on:search="fetchOptions(attr, $event)"
                  :selectable="
                    () =>
                      !attr.max_value ||
                      attr.value == null ||
                      (attr.value && attr.value.length < attr.max_value)
                  "
                  v-model="attr.value"
                  v-bind:filterable="false"
                  v-bind:options="attr.options"
                  v-bind:multiple="attr.multiple"
                  label="name"
                  v-bind:class="getSelectClass(attr.multiple)"
                  v-bind:placeholder="attr.placeholder"
                >
                  <span slot="no-options">{{ $t("noResultsToShow") }}</span>
                </v-select>
              </template>
              <!-- ./Select Options -->

              <!-- After Text -->
              <span class="ml-2 col-auto p-0">{{ attr.after_text }}</span>
              <!-- ./After Text -->
            </div>
          </div>
        </div>
        <!-- ./Template Condition -->

        <!-- Template Result -->
        <div class="form-group mb-xl-0">
          <div v-if="data.template.id == 'product-3'" class="d-flex">
            <div class="d-flex align-items-center mx-2">
              <v-select
                v-on:search="fetchOptions(computedTemplate.result, $event)"
                v-model="computedTemplate.result.product"
                v-bind:filterable="false"
                v-bind:options="computedTemplate.result.options"
                label="name"
                style="min-width: 150px"
                v-bind:placeholder="data.template.id == 'product-7' ? 'C' : 'B'"
              >
                <span slot="no-options">{{ $t("noResultsToShow") }}</span>
              </v-select>
              <span class="ml-2">ürününde</span>
            </div>
            <div class="d-flex align-items-center">
              <v-select
                v-bind:options="computedTemplate.result.type_options"
                v-bind:clearable="false"
                v-model="computedTemplate.result.type"
                label="name"
              >
                <span slot="no-options">{{ $t("noResultsToShow") }}</span>
              </v-select>
              <WeInput
                style="max-width: 90px"
                class="ml-2 mb-0"
                v-bind:filter-number="true"
                v-model="computedTemplate.result.value"
              />
              <span class="ml-2">indirim.</span>
            </div>
          </div>
          <!-- Brand 2 & Product 3 - 7 -->
          <div class="col-auto" v-if="data.template.id == 'product-7'">
            <div class="row align-items-center">
              <div class="col-auto pr-0">
                <div class="d-flex align-items-center">
                  <v-select
                    v-on:search="fetchOptions(computedTemplate.result, $event)"
                    v-model="computedTemplate.result.product"
                    v-bind:filterable="false"
                    v-bind:options="computedTemplate.result.options"
                    label="name"
                    style="min-width: 150px"
                    v-bind:placeholder="
                      data.template.id == 'product-7' ? 'C' : 'B'
                    "
                  >
                    <span slot="no-options">{{ $t("noResultsToShow") }}</span>
                  </v-select>
                  <span class="ml-2">ürünü</span>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center">
                  <WeInput
                    style="max-width: 90px"
                    class="mb-0"
                    placeholder="X"
                    v-bind:filter-number="true"
                    v-model="computedTemplate.result.price"
                  />
                  <span class="ml-2">TL.</span>
                </div>
              </div>
            </div>
          </div>
          <!-- ./Brand 2 & Product 3 - 7 -->

          <!-- Cart 1 -->
          <div class="col-auto" v-if="data.template.id == 'product-2'">
            <div class="d-flex align-items-center">
              <WeInput
                style="max-width: 90px"
                class="mb-0"
                placeholder="X"
                v-bind:filter-number="true"
                v-model="computedTemplate.result.price"
              />
              <span class="ml-2">TL.</span>
            </div>
          </div>
          <!-- Cart 1 -->

          <div
            v-if="
              data.template.id == 'category-3' ||
              data.template.id == 'cart-2' ||
              data.template.id == 'cart-3' ||
              data.template.id == 'cart-4' ||
              data.template.id == 'member-3' ||
              data.template.id == 'member-4'
            "
            class="col-auto"
          >
            <div class="d-flex align-items-center">
              <WeInput
                style="max-width: 90px"
                class="mb-0"
                v-bind:prepend="'%'"
                v-model="computedTemplate.result.value"
              />
              <span class="ml-2">indirim.</span>
            </div>
          </div>

          <!-- Category 1 -->
          <div class="col-auto" v-if="data.template.id == 'category-1'">
            <div class="d-flex align-items-center">
              <WeInput
                v-bind:placeholder="computedTemplate.result.placeholder || 'X'"
                v-bind:filter-number="true"
                v-model="computedTemplate.result.quantity"
                class="mb-0"
                style="max-width: 80px"
              />
              <span class="ml-2">adet öde.</span>
            </div>
          </div>
          <!-- Category 1 -->

          <!-- Category 2 -->
          <div
            class="col-auto"
            v-if="
              data.template.id == 'category-2' ||
              data.template.id == 'category-4' ||
              data.template.id == 'brand-1' ||
              data.template.id == 'brand-2'
            "
          >
            <div class="row align-items-center">
              <div class="col-auto pr-0">
                <div class="d-flex align-items-center">
                  <v-select
                    v-on:search="fetchOptions(computedTemplate.result, $event)"
                    v-model="computedTemplate.result.product"
                    v-bind:filterable="false"
                    v-bind:options="computedTemplate.result.options"
                    label="name"
                    style="min-width: 150px"
                    placeholder="A"
                  >
                    <span slot="no-options">{{ $t("noResultsToShow") }}</span>
                  </v-select>
                  <span class="ml-2">ürünü</span>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center">
                  <v-select
                    class="mr-2"
                    v-bind:options="computedTemplate.result.type_options"
                    v-bind:clearable="false"
                    v-model="computedTemplate.result.type"
                    label="name"
                  >
                    <span slot="no-options">{{ $t("noResultsToShow") }}</span>
                  </v-select>
                  <template v-if="computedTemplate.result.type.id !== 'free'">
                    <WeInput
                      style="max-width: 90px"
                      class="mb-0"
                      v-model="computedTemplate.result.value"
                    />
                  </template>
                  <div
                    v-if="computedTemplate.result.type.id !== 'free'"
                    class="ml-2"
                  >
                    indirim.
                  </div>
                  <div v-else class="ml-2">olacaktır.</div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./Category 2 -->

          <!-- Product 5 -->
          <div class="col-auto" v-if="data.template.id == 'product-5'">
            <div class="row align-items-center">
              <div class="col-auto pr-0">
                <div class="d-flex align-items-center">
                  <v-select
                    class="long-select"
                    v-on:search="fetchOptions(computedTemplate.result, $event)"
                    v-model="computedTemplate.result.product"
                    v-bind:filterable="false"
                    v-bind:multiple="false"
                    v-bind:selectable="
                      () =>
                        computedTemplate.result.product == null ||
                        computedTemplate.result.product.length < 2
                    "
                    v-bind:options="computedTemplate.result.options"
                    label="name"
                    placeholder="C"
                  >
                    <span slot="no-options">{{ $t("noResultsToShow") }}</span>
                  </v-select>
                  <span class="ml-2">ürünü</span>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center">
                  <template
                    v-if="computedTemplate.result.type.id == 'discount'"
                  >
                    <WeInput
                      style="max-width: 90px"
                      class="mb-0"
                      v-bind:prepend="'%'"
                      placeholder="X"
                      v-model="computedTemplate.result.value"
                    />
                  </template>
                  <v-select
                    class="ml-2"
                    v-bind:options="computedTemplate.result.type_options"
                    v-bind:clearable="false"
                    v-model="computedTemplate.result.type"
                    style="min-width: 150px"
                    label="name"
                  >
                    <span slot="no-options">{{ $t("noResultsToShow") }}</span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <!-- ./Product 5 -->

          <!-- Member 3 -->
          <div
            class="col-auto"
            v-if="
              data.template.id == 'member-1' ||
              data.template.id == 'member-2' ||
              data.template.id == 'product-4' ||
              data.template.id == 'cart-1'
            "
          >
            <div class="d-flex align-items-center">
              <v-select
                v-bind:options="computedTemplate.result.type_options"
                v-bind:clearable="false"
                v-model="computedTemplate.result.type"
                label="name"
              >
                <span slot="no-options">{{ $t("noResultsToShow") }}</span>
              </v-select>
              <WeInput
                style="max-width: 90px"
                class="ml-2 mb-0"
                v-bind:filter-number="true"
                v-model="computedTemplate.result.value"
              />
              <span class="ml-2">indirim.</span>
            </div>
          </div>
          <!-- ./Member 3 -->

          <!-- Category 7 && Product 10 -->
          <div
            class="col-auto"
            v-if="
              data.template.id == 'product-1' || data.template.id == 'brand-3'
            "
          >
            <div class="d-flex align-items-center">
              <WeInput
                style="max-width: 90px"
                class="mb-0"
                placeholder="Y"
                v-model="computedTemplate.result.value"
                v-bind:filter-number="true"
              />
              <span class="ml-2">adet öde.</span>
            </div>
          </div>
          <!-- ./Category 7 && Product 10  -->
        </div>
        <!-- /Template Result -->
      </div>
    </WeCard>
    <!-- ./Template Content -->

    <!-- Clear Template -->
    <span
      v-if="data.template && data.template.id"
      v-on:click="clearTemplate"
      class="btn btn-sm btn-outline-danger"
      >Şablonu Temizle</span
    >
    <!-- ./Clear Template -->
  </div>
</template>
<script>
export default {
  name: "ConditionTemplate",
  data() {
    return {
      baseList: [
        {
          name: "product",
          title: "Ürün",
        },
        {
          name: "category",
          title: "Kategori",
        },
        {
          name: "brand",
          title: "Marka",
        },
        {
          name: "member",
          title: "Üye",
        },
        {
          name: "cart",
          title: "Sepet",
        },
      ],
      data: {
        conditions: this.conditions,
        template: this.template,
        result: this.result,
        source: this.source,
        base: this.templateBase,
      },
    };
  },
  methods: {
    fetchOptions(attr, e) {
      if (e && e.length >= 3) {
        const data = {
          attr: attr,
          search_route: attr.search_route,
          text: e,
        };
        this.$emit("search-options", data);
      }
    },
    clearTemplate() {
      this.$swal({
        title: "İşlemi Onaylıyor musunuz ?",
        text: "Şablon içinde seçim yaptıysanız veriler temizlenecek",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.data.template.id = null;
          this.data.template.based = null;
          this.conditions.length = 0;
        }
      });
    },
    getSelectClass(multiple = false) {
      return !multiple ? "short-select" : "long-select";
    },
    changeBase(base) {
      this.computedBase = base.name;
    },
  },
  props: {
    source: {
      type: Object | Array,
      default: () => [],
    },
    result: {
      type: Object,
      default: () => {},
    },
    template: {
      type: Object,
      default: () => {},
    },
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
    templateBase: {
      default: "product",
    },
  },
  computed: {
    computedTemplate: {
      get() {
        const sourceTemplate = this.source[this.templateBase];
        const index = sourceTemplate.findIndex(
          (s) => s.template_id === this.template.id
        );
        if (index !== "-1" && index >= 0) {
          const condition = sourceTemplate[index];
          this.data.conditions = [condition];
          return condition;
        } else {
          return {};
        }
      },
    },
    computedBase: {
      get() {
        return this.templateBase;
      },
      set(value) {
        this.data.template.id = "-1";
        this.data.conditions = [];
        this.$emit("update:template-base", value);
      },
    },
    getBaseTitle() {
      const index = this.baseList.findIndex(
        (bl) => bl.name === this.templateBase
      );
      if (index >= 0) {
        return this.baseList[index].title;
      }
    },
  },
  watch: {
    "data.conditions": function (newVal) {
      this.$emit("update:conditions", newVal);
    },
  },
};
</script>
<style lang="scss">
.long-select {
  min-width: 250px !important;
}
.short-select {
  min-width: 150px !important;
}
</style>
